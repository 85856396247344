.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.parrot {
  height: 15em;
  width: 15em;
  image-rendering: pixelated;
}
