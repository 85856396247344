.container {
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/confetti.png');
  background-size: 40em 40em;
  z-index: 0;

  &.animated {
    top: -40em;
    left: -40em;
    right: -40em;
    bottom: -40em;
    animation: confetti 2s steps(8) infinite;
  }
}

.content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@keyframes confetti {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(40em, 40em, 0);
  }
}
