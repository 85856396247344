@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
html {
  background: black;
  font-family: 'Press Start 2P', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  font-size: calc(100vmin / 800 * 16);
  text-align: center;
  line-height: 1.5;
}

body {
  font-family: 'Press Start 2P', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex: 1 1;
  background: black;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

* {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-indent: 0;
  /* -webkit-appearance: none; */
}

.task-description p:last-child {
  font-weight: bold;
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Main_container__2poJJ{position:relative;min-height:100vh;height:100%;width:100vw}.Main_background__2S3fx{position:absolute;top:0;left:0;right:0;bottom:0;background-image:url(/static/media/confetti.24a19266.png);background-size:40em 40em;z-index:0}.Main_background__2S3fx.Main_animated__3etIp{top:-40em;left:-40em;right:-40em;bottom:-40em;animation:Main_confetti__1xHRD 2s steps(8) infinite}.Main_content__1dOHX{position:relative;flex:1 1;display:flex;flex-direction:column;justify-content:space-between;align-items:center;z-index:1}@keyframes Main_confetti__1xHRD{0%{transform:translate3d(0, 0, 0)}100%{transform:translate3d(40em, 40em, 0)}}

.Login_container__3P0z5{display:flex;margin:5em;flex-direction:column;flex-grow:1;color:white}.Login_googleBtn__pOIKf{background-color:black;padding:1em;margin:0.5em;font-size:1em;text-transform:uppercase;line-height:1;width:30em;height:4em;border:0.125em solid white}.Login_googleBtn__pOIKf:hover{color:#F06BA8}

.GithubButton_githubBtn__1RLww{text-decoration:none;color:white}.GithubButton_githubBtn__1RLww:hover{color:#F06BA8}

.GithubLogin_githubBtn__bYMng{padding:1em;margin:0.5em;font-size:1em;text-transform:uppercase;width:30em;height:4em;border:0.125em solid white;background-color:black}

.Parrot_container__34tU4{display:flex;flex-direction:row;justify-content:center;align-items:center}.Parrot_parrot__2ixnd{height:15em;width:15em;-ms-interpolation-mode:nearest-neighbor;image-rendering:-moz-crisp-edges;image-rendering:pixelated}

.ParrotLoader_label__2rLXE{color:white}

.Typography_common__3oegi{font-size:1em;line-height:1.5;color:orange;align-self:center;background-color:black;margin:0;padding:0}.Typography_h1__-u12W{font-size:3em}.Typography_h2__1C-zA{font-size:2em}.Typography_h3__1iJ8F{font-size:2em}.Typography_p__19l2J{font-size:1em}.Typography_small__1pCYF{font-size:0.75em}

.Welcome_beginButton__2BQ0p{font-size:1.2em;color:white;padding:20px;margin:1em auto 0 auto;border:2px solid white}.Welcome_beginButton__2BQ0p:hover{background-color:#F06BA8}

.Header_container__1OD2H{top:0;height:6em;box-sizing:border-box;padding:0.5em;display:flex;flex-direction:column;align-items:center;justify-content:center}.Header_container__1OD2H .Header_title__1hMfH{margin-top:5em}

.GameIntro_container__2KEUK{display:flex;margin:5em;flex-direction:column;justify-content:center;align-content:center;flex-grow:1}.GameIntro_container__2KEUK a{text-decoration:none}.GameIntro_storyContainer__LcvgB{margin:3em 5em 0 5em}.GameIntro_beginButton__1_3qF{font-size:1em;color:white;padding:20px;margin:1em auto 0 auto;border:2px solid white;width:50%}

form{padding:50px}.RegistrationForm_agreement__1Yh4e{font-size:0.8rem}.RegistrationForm_container__utrUT{display:flex;position:relative;padding-left:25px;margin-bottom:12px;cursor:pointer;color:white}.RegistrationForm_container__utrUT input[type="checkbox"]{position:absolute;opacity:0;cursor:pointer;height:0;width:0}.RegistrationForm_checkmark__64ER6{position:absolute;top:3px;left:0;height:20px;width:20px;background-color:#eee}.RegistrationForm_container__utrUT:hover input ~ .RegistrationForm_checkmark__64ER6{background-color:#ccc}.RegistrationForm_container__utrUT input:checked ~ .RegistrationForm_checkmark__64ER6{background-color:#2196F3}.RegistrationForm_checkmark__64ER6:after{content:"";position:absolute;display:none}.RegistrationForm_container__utrUT input:checked ~ .RegistrationForm_checkmark__64ER6:after{display:block}.RegistrationForm_container__utrUT .RegistrationForm_checkmark__64ER6:after{left:5px;top:2px;width:5px;height:10px;border:solid white;border-width:0 3px 3px 0;transform:rotate(45deg)}.RegistrationForm_error__10pSq{color:crimson;font-size:0.9vw}.RegistrationForm_terms__28KPb{color:#00C9EC;text-decoration:none}.RegistrationForm_terms__28KPb:hover{text-decoration:underline;color:#00C9EC}

.theme_form__2vTqK{display:flex;flex-direction:column;justify-content:space-between;align-items:center}.theme_button__3k9kf{min-width:30em;max-width:100em;min-height:2em;margin:0.5em;padding:1em;border-style:none;border-radius:0.5em;background-color:#F06BA8;box-shadow:none;font-size:1em;font-weight:normal;color:#fff;cursor:pointer;outline:none}.theme_button__3k9kf:disabled{background-color:darkblue;cursor:not-allowed}.theme_button__3k9kf:active{background-color:lightblue;color:black;cursor:progress}.theme_answerButton__1gW7s{min-width:35em;max-width:70em;min-height:2em;margin:0.5em;padding:1em;border-style:none;border-radius:0.5em;background-color:#3137e1;box-shadow:none;font-size:1em;font-weight:normal;color:#fff;cursor:pointer;outline:none}.theme_answerButton__1gW7s:disabled{background-color:darkblue;cursor:not-allowed}.theme_answerButton__1gW7s:active{background-color:lightblue;color:black;cursor:progress}.theme_field__P3Sdd{color:black;width:30em;padding:1em;margin:0.5em;font-size:1em;font-weight:normal;border-radius:0.5em;border:1px solid #ccc}

.TaskView_error__2rjIC{color:crimson;font-size:1vw}.TaskView_answer__2IY-y{outline:none;color:black;width:53vw;padding:1em;margin:0.5em;font-size:1em;font-weight:normal;border-radius:0.5em;border:1px solid #ccc}.TaskView_submit__jCGjH{min-width:20em;max-width:30em;min-height:1em;margin:0.1em;padding:0.6em;border-style:none;border-radius:0.5em;background-color:#F06BA8;box-shadow:none;font-size:1em;font-weight:normal;color:#fff;cursor:pointer;outline:none}.TaskView_task__8LUQ4{padding-top:7em}.TaskView_task__8LUQ4 .TaskView_title__39l89{color:white;padding-bottom:1em}.TaskView_task__8LUQ4 .TaskView_description__F49iV{padding:4em;margin:auto;background-color:black;color:orange;font-family:Consolas, 'Liberation Mono', Menlo, Courier, monospace;text-align:justify;max-width:1200px}.TaskView_task__8LUQ4 .TaskView_samples__qdms5{display:flex;justify-content:space-between;margin:2em 7em 0 7em}.TaskView_task__8LUQ4 .TaskView_yours__2Ib6S{padding:1em;background-color:black}.TaskView_task__8LUQ4 a{color:#F06BA8}.TaskView_task__8LUQ4 blockquote{font-style:italic;padding-left:1em}.TaskView_task__8LUQ4 .TaskView_sample__3lASQ{font-family:'Press Start 2P', sans-serif;font-size:1em;line-height:1.5;color:white;align-self:center;background-color:black;margin:0;padding:0}.TaskView_task__8LUQ4 .TaskView_form__1k3Nn{width:50%;margin:auto}.TaskView_task__8LUQ4 .TaskView_inputBtn__3_yrn{margin:5em auto}.TaskView_task__8LUQ4 .TaskView_inputBtn__3_yrn .TaskView_inputBtnLink__1GN9A{width:30%;border:3px solid white;color:white;padding:2em;cursor:pointer;text-decoration:none;background-color:black}.TaskView_task__8LUQ4 .TaskView_backBtn__1elDV{background:none;padding:1em;margin:0.5em;font-size:1em;text-transform:uppercase;line-height:1;width:30em;height:4em;border:0.125em solid white}

.Info_container__2GVaB{padding:1em;margin:0.5em;font-size:2em;text-transform:uppercase;line-height:1.5;width:20em;border:0.125em solid white}.Info_container__2GVaB.Info_default__ghNIF{background-color:black;color:white;border-color:white}.Info_container__2GVaB.Info_success__jMhPY{background-color:white;color:black;border-color:black;animation:Info_flash__2Zwma 1s steps(1) infinite}.Info_container__2GVaB.Info_fail__1DkL3{background-color:red;color:black;border-color:black}@keyframes Info_blink__25mDO{0%{opacity:1}50%{opacity:0}100%{opacity:1}}@keyframes Info_flash__2Zwma{0%{background-color:#e0488f;color:black;border-color:black}25%{background-color:#2275b8;color:white;border-color:white}50%{background-color:#4db03e;color:black;border-color:black}75%{background-color:#8b529c;color:white;border-color:white}100%{background-color:#e0488f;color:black;border-color:black}}

.Parrot_container__UtTQg{display:flex;flex-direction:row;justify-content:center;align-items:center}.Parrot_parrot__1WWu-{height:15em;width:15em;-ms-interpolation-mode:nearest-neighbor;image-rendering:-moz-crisp-edges;image-rendering:pixelated}

.TaskInput_task__10ME3{padding-top:7em;margin-bottom:3em}.TaskInput_task__10ME3 .TaskInput_data__3Y6yd{font-family:Consolas, 'Liberation Mono', Menlo, Courier, monospace;font-size:1em;background-color:black;color:orange;border:none;resize:none;padding:1em;max-width:80vw;margin-top:2em;margin-bottom:2em}.TaskInput_task__10ME3 .TaskInput_title__DtJIO{color:white;padding-bottom:1em}.TaskInput_submit__10wOa{min-width:20em;max-width:30em;min-height:1em;margin:0.1em;padding:0.6em;border-style:none;border-radius:0.5em;background-color:#F06BA8;box-shadow:none;font-size:1em;font-weight:normal;color:#fff;cursor:pointer;outline:none}.TaskInput_copy__1HoQO{min-width:20em;max-width:30em;min-height:1em;margin:0.1em;padding:0.6em;border-style:none;border-radius:0.5em;background-color:orange;box-shadow:none;font-size:1em;font-weight:normal;color:#fff;cursor:pointer;outline:none}

.GameOverview_overview__27lvl{width:43vw;padding-top:5em}.GameOverview_overview__27lvl .GameOverview_grid__19pk-{display:flex;flex-wrap:wrap}.GameOverview_overview__27lvl .GameOverview_credits__3E1kM{font-size:0.5em;color:orange;margin:2em}.GameOverview_overview__27lvl .GameOverview_credits__3E1kM a{color:white}.GameOverview_overview__27lvl .GameOverview_cell__2-9jg{width:50%;background:#181818;border:1px solid black;cursor:pointer}.GameOverview_overview__27lvl .GameOverview_congratulations__20oNC{background-color:#F06BA8;border-radius:1em;padding:1em;margin-bottom:2em}.GameOverview_overview__27lvl .GameOverview_congratulations__20oNC .GameOverview_hurry__2Inbw{font-size:0.6em;margin-top:1em}.GameOverview_overview__27lvl .GameOverview_congratulations__20oNC a{color:white}.GameOverview_overview__27lvl .GameOverview_congratulations__20oNC .GameOverview_discount__1VVAb{margin:1em;color:white}

.GameTile_tile__mrxlR img{height:100%;width:100%}

