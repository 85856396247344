.container {
  display: flex;
  margin: 5em;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-grow: 1;

  a {
    text-decoration: none;
  }
}

.storyContainer {
  margin: 3em 5em 0 5em;
}

.beginButton {
  font-size: 1em;
  color: white;
  padding: 20px;
  margin: 1em auto 0 auto;
  border: 2px solid white;
  width: 50%;
}