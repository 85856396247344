.container {
  //position: sticky;
  top: 0;
  height: 6em;
  box-sizing: border-box;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin-top: 5em;
  }
}
