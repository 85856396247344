.beginButton {
  font-size: 1.2em;
  color: white;
  padding: 20px;
  margin: 1em auto 0 auto;
  border: 2px solid white;
}

.beginButton:hover {
  background-color: #F06BA8;
}
