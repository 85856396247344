.common {
  font-size: 1em;
  line-height: 1.5;
  color: orange;
  align-self: center;
  background-color: black;
  margin: 0;
  padding: 0;
}

.h1 {
  font-size: 3em;
}
.h2 {
  font-size: 2em;
}
.h3 {
  font-size: 2em;
}

.p {
  font-size: 1em;
}

.small {
  font-size: 0.75em;
}
