.container {
  padding: 1em;
  margin: 0.5em;
  font-size: 2em;
  text-transform: uppercase;
  line-height: 1.5;
  width: 20em;
  border: 0.125em solid white;

  &.default {
    background-color: black;
    color: white;
    border-color: white;
  }

  &.success {
    background-color: white;
    color: black;
    border-color: black;
    animation: flash 1s steps(1) infinite;
  }

  &.fail {
    background-color: red;
    color: black;
    border-color: black;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    background-color: #e0488f;
    color: black;
    border-color: black;
  }
  25% {
    background-color: #2275b8;
    color: white;
    border-color: white;
  }
  50% {
    background-color: #4db03e;
    color: black;
    border-color: black;
  }
  75% {
    background-color: #8b529c;
    color: white;
    border-color: white;
  }
  100% {
    background-color: #e0488f;
    color: black;
    border-color: black;
  }
}
