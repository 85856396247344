.error {
  color: crimson;
  font-size: 1vw;
}

.answer {
  outline: none;
  color: black;
  width: 53vw;
  padding: 1em;
  margin: 0.5em;
  font-size: 1em;
  font-weight: normal;
  border-radius: 0.5em;
  border: 1px solid #ccc;
}

.submit {
  min-width: 20em;
  max-width: 30em;
  min-height: 1em;
  margin: 0.1em;
  padding: 0.6em;
  border-style: none;
  border-radius: 0.5em;
  background-color: #F06BA8;
  box-shadow: none;
  font-size: 1em;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.task {
  padding-top: 7em;

  .title {
    color: white;
    padding-bottom: 1em;
  }

  .description {
    padding: 4em;
    margin: auto;
    background-color: black;
    color: orange;
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    text-align: justify;
    max-width: 1200px;
  }

  .samples {
    display: flex;
    justify-content: space-between;
    margin: 2em 7em 0 7em;
  }

  .yours {
    padding: 1em;
    background-color: black;
  }

  a {
    color: #F06BA8;
  }

  blockquote {
    font-style: italic;
    padding-left: 1em;
  }

  .sample {
    font-family: 'Press Start 2P', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    color: white;
    align-self: center;
    background-color: black;
    margin: 0;
    padding: 0;
  }

  .form {
    width: 50%;
    margin: auto;
  }

  .inputBtn {
    margin: 5em auto;

    .inputBtnLink {
      width: 30%;
      border: 3px solid white;
      color: white;
      padding: 2em;
      cursor: pointer;
      text-decoration: none;
      background-color: black;
    }
  }

  .backBtn {
    background: none;
    padding: 1em;
    margin: 0.5em;
    font-size: 1em;
    text-transform: uppercase;
    line-height: 1;
    width: 30em;
    height: 4em;
    border: 0.125em solid white;

  }
}