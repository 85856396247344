//.container {
//  display: block;
//  margin: 5em;
//  flex-direction: column;
//  flex-grow: 1;
//  padding: 50px;
//}

form {
  padding: 50px;
}

.agreement {
  font-size: 0.8rem;
}

.container {
  display: flex;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  color: white;
}

.container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.error {
  color: crimson;
  font-size: 0.9vw;
}

.terms {
  color: #00C9EC;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #00C9EC;
  }
}