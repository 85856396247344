.task {
  padding-top: 7em;
  margin-bottom: 3em;

  .data {
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 1em;
    background-color: black;
    color: orange;
    border: none;
    resize: none;
    padding: 1em;
    max-width: 80vw;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .title {
    color: white;
    padding-bottom: 1em;
  }
}

.submit {
  min-width: 20em;
  max-width: 30em;
  min-height: 1em;
  margin: 0.1em;
  padding: 0.6em;
  border-style: none;
  border-radius: 0.5em;
  background-color: #F06BA8;
  box-shadow: none;
  font-size: 1em;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  outline: none;
}

.copy {
  min-width: 20em;
  max-width: 30em;
  min-height: 1em;
  margin: 0.1em;
  padding: 0.6em;
  border-style: none;
  border-radius: 0.5em;
  background-color: orange;
  box-shadow: none;
  font-size: 1em;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  outline: none;
}