.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.button {
  min-width: 30em;
  max-width: 100em;
  min-height: 2em;
  margin: 0.5em;
  padding: 1em;
  border-style: none;
  border-radius: 0.5em;
  background-color: #F06BA8;
  box-shadow: none;
  font-size: 1em;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  outline: none;

  &:disabled {
    background-color: darkblue;
    cursor: not-allowed;
  }

  &:active {
    background-color: lightblue;
    color: black;
    cursor: progress;
  }
}

.answerButton {
  min-width: 35em;
  max-width: 70em;
  min-height: 2em;
  margin: 0.5em;
  padding: 1em;
  border-style: none;
  border-radius: 0.5em;
  background-color: #3137e1;
  box-shadow: none;
  font-size: 1em;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  outline: none;

  &:disabled {
    background-color: darkblue;
    cursor: not-allowed;
  }

  &:active {
    background-color: lightblue;
    color: black;
    cursor: progress;
  }
}

.field {
  color: black;
  width: 30em;
  padding: 1em;
  margin: 0.5em;
  font-size: 1em;
  font-weight: normal;
  border-radius: 0.5em;
  border: 1px solid #ccc;
}
