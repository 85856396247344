.container {
  display: flex;
  margin: 5em;
  flex-direction: column;
  flex-grow: 1;
  color: white;
}
.googleBtn {
  background-color: black;
  padding: 1em;
  margin: 0.5em;
  font-size: 1em;
  text-transform: uppercase;
  line-height: 1;
  width: 30em;
  height: 4em;
  border: 0.125em solid white;

  &:hover {
    color: #F06BA8
  }
}