.overview {
  width: 43vw;
  padding-top: 5em;

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .credits {
    font-size: 0.5em;
    color: orange;
    margin: 2em;

    a {
      color: white;
    }
  }

  .cell {
    width: 50%;
    background: #181818;
    border: 1px solid black;
    cursor: pointer;
  }

  .congratulations {
    background-color: #F06BA8;
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 2em;

    .hurry {
      font-size: 0.6em;
      margin-top: 1em;
    }

    a {
      color: white;
    }

    .discount {
      margin: 1em;
      color: white;
    }
  }
}