@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');

html {
  background: black;
  font-family: 'Press Start 2P', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  font-size: calc(100vmin / 800 * 16);
  text-align: center;
  line-height: 1.5;
}

body {
  font-family: 'Press Start 2P', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex: 1;
  background: black;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

* {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  text-indent: 0;
  /* -webkit-appearance: none; */
}

.task-description p:last-child {
  font-weight: bold;
  color: white;
}
